.Button_button {
  width: 125px;
  height: 45px;
  background: #fff;
  color: var(--consumer-pink);
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
}
.b2b .Button_button {
  color: var(--business-blue);
}

.Button_button:hover {
  background: var(--consumer-pink);
  color: #fff;
  cursor: pointer;
}
.b2b .Button_button:hover {
  background: var(--business-blue);
}

.Button_button:active,
.Button_button:focus {
  color: #fff;
  background: #b8107c;
  outline: none;
}

.Button_button.Button_lightBackground {
  border: 1px solid var(--consumer-pink);
}

.b2b .Button_button.Button_lightBackground {
  border: 1px solid var(--business-blue);
}

.Button_button.Button_medium {
  width: 220px;
}

.Button_button.Button_large {
  width: 280px;
}
